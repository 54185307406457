import React from 'react';
import './PortfolioBox.scss';
import { navigate } from 'gatsby';
import { BTN_STYLES, Button } from '../Button/Button';
import ROUTES from '../../../constants/routes';
import { PortfolioBoxProps } from './types';

const PortfolioBox = ({ data, classNameButton, className = 'portfolioBox' }: PortfolioBoxProps) => {
  const {
    workDescription,
    cardTitle,
    image: {
      asset: { url },
    },
    slug: { current },
  } = data;

  return (
    <div className={className}>
      <div className="box_text">
        <h3>{cardTitle}</h3>
        <p>{workDescription}</p>
        <Button
          title="View Project"
          className={classNameButton ? classNameButton : BTN_STYLES.BTN_OUTLINE_SMALL}
          onClick={() => navigate(ROUTES.WORKS + `${current}`)}
        />
      </div>
      <div className="box_visual">
        <img src={url} alt="project work" width="526px" height="auto" />
      </div>
    </div>
  );
};

export default PortfolioBox;
