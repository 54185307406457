import React from 'react';
import './Preloader.scss';
import SVG_ICONS_PRELOADER from '../../../images/Preloader/svg';

const Preloader = () => {
  return (
    <div className="preloader">
      <img loading="lazy" src={SVG_ICONS_PRELOADER.LOADER} alt="loading" />
    </div>
  );
};

export default Preloader;
