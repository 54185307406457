import React from 'react';
import './Callback.scss';
import { navigate } from 'gatsby';
import { BTN_STYLES, Button } from '../Button/Button';
import ROUTES from '../../../constants/routes';

const Callback = () => {
  return (
    <div className="callback">
      <h3>Talk to our experts!</h3>
      <p>Are you planning to implement a Frontend solution? contact us now to speak with our specialists.</p>
      <Button
        title="Contact Us"
        onClick={() => navigate(ROUTES.CONTACT_US)}
        className={BTN_STYLES.BTN_PRIMARY_MEDIUM}
      />
    </div>
  );
};

export default Callback;
