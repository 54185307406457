import React, { useEffect, useState } from 'react';
import home from '../../../images/_shared/svg/home.svg';
import arrowRight from '../../../images/_shared/svg/arrow_right.svg';
import './Breadcrumbs.scss';

type BreadcrumbsProps = {
  isDark?: boolean;
};

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ isDark }) => {
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const [isWindow, setIsWindow] = useState<string>('');
  const pathNames = isWindow.split('/').filter((name) => name !== '');
  const formattedNames = pathNames.map((name) => name.split('-').join(' '));
  const specSymbols = pathNames.length + 1;
  let symbolsLimit = 50;
  let numberOfSymbols = 0;

  const resizeScreen = () => {
    setScreenWidth(window.innerWidth);
  };

  const checkScreenWidth = () => {
    if (screenWidth <= 400) {
      symbolsLimit = 12;
    }

    if (screenWidth > 400) {
      symbolsLimit = 22;
    }

    if (screenWidth > 500) {
      symbolsLimit = 35;
    }

    if (screenWidth > 768) {
      symbolsLimit = 50;
    }
  };

  useEffect(() => {
    setScreenWidth(window.innerWidth);
    setIsWindow(window.location.pathname);
    window.addEventListener('resize', resizeScreen);
    window.addEventListener('resize', checkScreenWidth);

    return () => {
      window.removeEventListener('resize', resizeScreen);
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, [screenWidth, isWindow]);

  return (
    <div className="breadCrumbs">
      <div className="container">
        <div className="breadCrumbsWrapper">
          <ul className="breadCrumbs__items">
            {formattedNames.map((name, idx) => {
              const pointerEventCondition =
                formattedNames.length < 2 ||
                name === 'author' ||
                name === 'industries' ||
                name === 'services' ||
                idx === 1
                  ? 'none'
                  : 'auto';

              const shrinkPathname = (pathname: string) => {
                const endIdx = pathname.length - (symbolsLimit + specSymbols - numberOfSymbols) - specSymbols + 1;
                const endIndex = pathname.length - (numberOfSymbols - specSymbols - (symbolsLimit + specSymbols)) + 1;

                if (numberOfSymbols + specSymbols > symbolsLimit) {
                  return (
                    (symbolsLimit + specSymbols > numberOfSymbols && `${pathname.slice(0, endIdx)}...`) ||
                    (symbolsLimit + specSymbols < numberOfSymbols && `${pathname.slice(0, endIndex)}...`)
                  );
                }

                return pathname;
              };

              checkScreenWidth();
              numberOfSymbols += name.length;

              return (
                <div key={idx} className="breadCrumbWrapper">
                  {idx === 0 && (
                    <li className="breadCrumbs__item">
                      <a href="/" className="breadCrumbs__link home">
                        <img
                          loading="lazy"
                          src={home}
                          className={'home__icon' + (isDark ? ' iconIsDark' : '')}
                          alt="home-icon"
                        />
                      </a>
                    </li>
                  )}
                  <img
                    src={arrowRight}
                    className={'breadCrumbs__arrow-right' + (isDark ? ' iconIsDark' : '')}
                    alt="arrow-right"
                  />
                  <li key={idx} className="breadCrumbs__item">
                    <a
                      style={{ pointerEvents: pointerEventCondition }}
                      className={'breadCrumbs__link isWhite' + (isDark ? ' isDark' : '')}
                      href={`/${name}`}
                    >
                      {shrinkPathname(name)}
                    </a>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
