import * as React from 'react';
import PageLayout from '../../components/_Shared/PageLayout/PageLayout';
import 'normalize.css';
import '../../globalStyles/_fonts.scss';
import '../../globalStyles/_styles.scss';
import WorksContent from '../../components/Works/Works';
import { graphql } from 'gatsby';
import { worksTitle } from '../../seoData/titles';
import { worksDescription } from '../../seoData/descriptions';
import { WorksCardProps } from '../../components/Works/types';

export const query = graphql`
  query MyWorks {
    allSanityCaseStudy {
      nodes {
        title
        cardTitle
        workDescription
        description
        id
        slug {
          current
        }
        categories {
          sanityId
          title
        }
        image {
          asset {
            url
          }
        }
      }
    }
    allSanityFooter {
      nodes {
        title
        footerRow {
          title
          link
        }
      }
    }
  }
`;

const Works = (props: WorksCardProps) => {
  const { allSanityFooter } = props.data;

  return (
    <PageLayout footerData={allSanityFooter} title={worksTitle} description={worksDescription}>
      <WorksContent data={props.data.allSanityCaseStudy} />
    </PageLayout>
  );
};

export default Works;
