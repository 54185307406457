import * as React from 'react';
import './Works.scss';
import { useEffect, useState } from 'react';
import PortfolioBox from '../_Shared/PortfolioBox/PortfolioBox';
import Callback from '../_Shared/Callback/Callback';
import Preloader from '../_Shared/Preloader/Preloader';
import Navigation from '../_Shared/Navigation/Navigation';
import BreadCrumbs from '../_Shared/Breadcrumbs/Breadcrumbs';
import { WorksContentData } from './types';
import { worksCategories } from './defaultData';

const WorksContent = ({ data }: WorksContentData) => {
  const caseStudyCard = data.nodes;
  const [selectedCategoryId, setSelectedCategoryId] = useState(0);
  const [loading] = useState(false);
  const [portfolioList, setPortfolioList] = useState(caseStudyCard);
  const filterByCategory = () => {
    const filtered = caseStudyCard.filter((card) => card.categories[0].sanityId === selectedCategoryId);

    return setPortfolioList(() => (selectedCategoryId === 0 ? caseStudyCard : filtered));
  };

  useEffect(() => {
    filterByCategory();
  }, [selectedCategoryId]);

  return (
    <>
      <section className="works">
        <div className="works__breadCrumbs">
          <BreadCrumbs isDark />
        </div>
        {loading ? (
          <Preloader />
        ) : (
          <div className="works__container">
            <Navigation
              categories={worksCategories}
              title="Works"
              change={selectedCategoryId}
              setChange={setSelectedCategoryId}
            />
            {portfolioList.map((portfolioCard, index: number) => {
              return <PortfolioBox data={portfolioCard} key={index} />;
            })}
            <Callback />
          </div>
        )}
      </section>
    </>
  );
};

export default WorksContent;
